class Comment {
    constructor() {
        this.element = 'comment';
    }

    validateFormComment(){
        $('input[type=text]').addClass('validate[required, custom[onlyLetterSp]]');
        $('input[type=email]').addClass('validate[required,custom[email]]');
        $('input[type=tel]').addClass('validate[required,custom[phone]]');
        $('textarea').addClass('validate[required,minSize[50]');
        $('#commentform').validationEngine('attach', {promptPosition : "topLeft"});
    }
}

export default Comment;