class Homepage {
    constructor() {
        this.element = 'homepage';
    }

    init(){
        this.ActusSlideshow();
        this.actuWidth();
        this.contactBlockClick();
        this.scheduleBlockClick();
        this.actuBlockClick();

        $(window).resize(() => this.actuWidth());
    }

    ActusSlideshow(){
        $('.multiple-items').slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 2300,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2
                    }
                }
                ,
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
    actuWidth(){
        let windowWidth = $(window).width();
        let presentationWidth = $(".presentation").innerWidth();
        let actuWidth = ((windowWidth - presentationWidth)/2)+presentationWidth;
        if($(window).width() > 1024) {
            $(".actus-container").css("width", actuWidth);
        }
        else{
            $(".actus-container").css("width", "100%");
        }
    }
    contactBlockClick () {
        $(".contact-content").click(function(){
            $(this).find('a').get(0).click();
        });
    }
    scheduleBlockClick () {
        $(".schedule-item").click(function(){
            if(!($(this).hasClass( "empty-readmore"))) {
                $(this).find('a').get(0).click();
            }
        });
    }
    actuBlockClick(){
        $(".actu").click(function(){
            $(this).find('a').get(0).click();
        });
    }
}

export default Homepage;