class Common {

    constructor() {
        this.element = 'common';
    }

    init(){
        this.openMenu();
        this.closeMenu();
        this.handleHeight();
        this.removeBlankSpaceTable();
        this.appendPostDate();
        this.prependPostCateg();
        this.toolMenuTouch();

        $(window).resize(() => this.handleHeight());
    }
    openMenu(){
        $("#hambMenu").click(function() {
            document.getElementById("mainMenu").style.height = "100%";
            document.getElementById("hambMenu").style.opacity = "0";
        });
    }
    closeMenu(){
        $(".closebtn").click(function() {
            document.getElementById("mainMenu").style.height = "0%";
            document.getElementById("hambMenu").style.opacity = "1";
        });
    }

    handleHeight(){
        let windowHeight = $(window).height();
        let headerHeight = $('header').height();
        let footerHeight = $('footer').height();
        let bodyHeight = windowHeight - (headerHeight+footerHeight);
        $("#content").css("min-height",bodyHeight);
    }

    removeBlankSpaceTable(){
        $("td").each(function() {
            const $this = $(this);
            $this.html($this.html().replace(/&nbsp;/g, ''));
        });
    }
    appendPostDate(){
        $('#post-date').insertAfter($('.elementor-section-wrap > section:first-child .elementor-text-editor h2'));
    }
    prependPostCateg(){
        $('#post-categ').insertBefore($('.elementor-section-wrap > section:first-child .elementor-text-editor h2'));
    }
    toolMenuTouch(){
        $('.dropdown').on({ 'click' : function(){
            $(".dropdown-content").toggleClass('open');
        } });
    }
}

export default Common;