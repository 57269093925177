import Common from './common';
import Homepage from './homepage';
import Contact from './contact';
import Comment from './comment';

// Utilisation de $ pour Jquery
window.$ = jQuery;

// Message navigateur obsolète
function $buo_f () {
    let e = document.createElement('script');
    e.src = '//browser-update.org/update.min.js';
    document.body.appendChild(e)
}
try {
    document.addEventListener('DOMContentLoaded', $buo_f, false)
}
catch (e) {
    window.attachEvent('onload', $buo_f)
}

// Initialisation de la classe Common, utilisée pour tout le site
let global = new Common();
let body = document.body;

global.init();

$(document).ready(() => {
    if (body.classList.contains('home')) {
        let homepage = new Homepage();
        homepage.init();
    }
    if (body.classList.contains('post-template-page-elementor')) {
        let comment = new Comment();
        comment.validateFormComment();
    }
    if (body.classList.contains('page-template-page-contact')) {
        let contact = new Contact();
        contact.validateForm();
    }
});


